import React from 'react'
import resume from "../assets/resume.pdf"

const Navbar = () => (
    <nav className="d-flex mt-3 flex-column flex-md-row justify-content-between">
        <div className='text-md-start px-3'><p id="name" className="name display-6">Phoebe Torchia</p></div>
        <div className="fs-6 align-self-center px-3 d-flex">
            <a className="menuLinks" href={resume} download="resume">Resume</a>
            <a className="menuLinks" href="https://www.linkedin.com/in/phoebetorchia/">LinkedIn</a>
            <div className='dropdown'>
                <a className="menuLinks dropdown-toggle" href="#" data-bs-toggle="dropdown">Github</a>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <li><a id="dd" class="dropdown-item" href="https://github.com/fennel-ptorchia">fennel</a></li>
                    <li><a class="dropdown-item" href="https://github.com/oterech">personal</a></li>
                </ul>
            </div>
            <a className="menuLinks" href="mailto:torchiaphoebe@gmail.com">Email</a>
        </div>
    </nav>
)
export default Navbar

