import './App.css';
import About from './components/About';
import Navbar from './components/Navbar';

function App() {
  return (
    <div className="App">
      <Navbar />
      <About />
      {/* <Projects /> */}
      {/* <Stack /> */}
    </div>
  );
}

export default App;