import React from "react";

const About = () => (

  <div className="mt-5 mx-auto container-sm row text-justify">
    <p className="fs-6 mx-auto col-md-8 col-lg-6">Hi! I'm Phoebe. I love working on creative products with strong missions. Focused on developing great user experiences and product minded implementation, with a passion for bug squashing. <a className="menuLinks" href="mailto:torchiaphoebe@gmail.com">Contact me</a>for contract or part-time work.</p></div>


);

export default About;
